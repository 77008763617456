import isEmpty from 'lodash/isEmpty';
import lowerFirst from 'lodash/lowerFirst';

import { TextUtils } from 'repositories/_utils/TextUtils';

const requiredFields = ['Id', 'ShortDescription', 'Title'];

const getFieldSchemaId = (data = [], selectedTableSchemaId, rootPkFieldId, rootTableName) => {
  if (!isEmpty(data)) {
    const table = data.filter(table => table.tableSchemaId === selectedTableSchemaId);
    if (!isEmpty(table)) {
      const { fieldSchema, fieldId } = table[0].records[0].fields.filter(field => {
        if (TextUtils.areEquals(table[0].name, rootTableName)) return TextUtils.areEquals(field.fieldId, rootPkFieldId);

        return TextUtils.areEquals(field?.referencedField?.idPk, rootPkFieldId);
      })[0];

      return { fieldSchema, fieldId };
    }
  }

  return { fieldSchema: null, fieldId: null };
};

const getTypeList = (records = []) => {
  const typeList = records.map(record => {
    let data = {};

    record.elements.forEach(
      element => (data = { ...data, [lowerFirst(element.name)]: element.value, recordId: record.recordId })
    );
    return data;
  });

  return {
    single: typeList.sort((a, b) => a.id - b.id)
  };
};

const checkErrors = (data, rootPkFieldId) => {
  if (isEmpty(data)) return {};

  let errors = {};

  const rootTable = data.filter(table => table.isRootTable === true);
  const restTables = data.filter(table => !(table.isRootTable === true));

  rootTable.forEach(table => {
    const fields = requiredFields.map(field => {
      const filteredField = table.elements.filter(element => TextUtils.areEquals(element.name, field))[0];

      return { name: field, isMissing: !filteredField.hasOwnProperty('fieldId') };
    });

    errors = {
      ...errors,
      [table.name]: { fields, table: { name: table.name, isMissing: !table.hasOwnProperty('tableSchemaId') } }
    };
  });

  restTables.forEach(table => {
    const fk = table.records
      ? table.records[0].fields.filter(field => field?.referencedField?.idPk === rootPkFieldId)
      : [];

    errors = {
      ...errors,
      [table.name]: {
        fields: [{ name: fk[0].name, isMissing: !isEmpty(fk) ? !fk[0].hasOwnProperty('fieldId') : true }],
        table: { name: table.name, isMissing: !table.hasOwnProperty('tableSchemaId') }
      }
    };
  });

  return errors || {};
};

const getSingleRecordOption = singleRecord => {
  if (singleRecord.elements.find(el => TextUtils.areEquals(el.name, 'Id')).value === '') {
    return `${singleRecord.elements.find(el => TextUtils.areEquals(el.name, 'Id')).value}`;
  }

  return `${singleRecord.elements.find(el => TextUtils.areEquals(el.name, 'Id')).value}`;
};

const hasErrors = (data, rootPkFieldId) => {
  const errors = [];

  const rootTable = data.filter(table => table.isRootTable === true);
  const restTables = data.filter(table => !(table.isRootTable === true));

  rootTable.forEach(table => {
    requiredFields.forEach(field => {
      const filteredField = table.elements.filter(element => TextUtils.areEquals(element.name, field))[0];

      errors.push(!filteredField.hasOwnProperty('fieldId'), !table.hasOwnProperty('tableSchemaId'));
    });
  });

  restTables.forEach(table => {
    const fk = table.records
      ? table.records[0].fields.filter(field => field?.referencedField?.idPk === rootPkFieldId)
      : [];
    errors.push(!isEmpty(fk) ? !fk[0].hasOwnProperty('fieldId') : true, !table.hasOwnProperty('tableSchemaId'));
  });

  return errors.includes(true);
};

const parseListOfSingleEntities = (records = []) => {
  const options = [];
  records.forEach(record => {
    if (
      record.elements.find(el => TextUtils.areEquals(el.name, 'IsGroup'))?.value === 'Single' &&
      record.elements.find(el => TextUtils.areEquals(el.name, 'Id')) &&
      record.elements.find(el => TextUtils.areEquals(el.name, 'Title'))
    ) {
      options.push(getSingleRecordOption(record));
    }
  });

  return options.sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));
};

export const EntitiesWebformUtils = {
  checkErrors,
  getFieldSchemaId,
  getTypeList,
  hasErrors,
  parseListOfSingleEntities
};
